<template>
  <v-card min-width="600">
    <v-skeleton-loader
      class="ma-5"
      v-if="loading"
      type="card-heading, divider, list-item-two-line, card-heading, divider, list-item-two-line"
    ></v-skeleton-loader>
    <template v-else-if="Object.keys(items).length > 0 && items.constructor === Object">
      <v-list v-for="(schedules, header, index) in items" two-line subheader :key="header">
        <v-divider v-if="index > 0"></v-divider>
        <v-subheader
          :class="!['Due Appointments', 'Today'].includes(header) || 'red--text font-weight-bold'"
          >{{ header }}</v-subheader
        >

        <v-list-item
          v-for="(schedule, index2) in schedules"
          :key="index2"
          @click="onItemClick(schedule)"
        >
          <v-list-item-content>
            <v-list-item-title v-text="schedule.ProjectNM"></v-list-item-title>
            <v-list-item-subtitle v-text="schedule.object_label"></v-list-item-subtitle>
            <v-list-item-subtitle v-text="schedule.scheduled_time"></v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon @click.stop="deleteSchedule(schedule.id)">
              <v-icon color="grey lighten-1">mdi-trash-can-outline</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>
    <template v-else>
      <v-card-text class="text--primary mt-4 text--disabled"
        >You have no scheduled call</v-card-text
      >
    </template>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      confirmDialog: false,
      loading: false,
      items: {}
    };
  },
  methods: {
    onItemClick(e) {
      this.openTMUI(e.COMPANY_OBJECT_ID, e.OBJECT_ID, { ccmsId: e.ccmsId });
    },
    deleteFromList(id) {
      let newItems = {};
      for (let [key, schedules] of Object.entries(this.items)) {
        let newSchedules = schedules.filter((schedule) => schedule.id != id);
        if (newSchedules.length > 0) {
          newItems[key] = newSchedules;
        }
      }

      this.items = newItems;
    },
    deleteSchedule(id) {
      this.$root.$confirm("Delete", "Are you sure?", { color: "red" }).then((confirm) => {
        if (confirm) {
          this.$axios
            .delete("/telemarketer/schedule/delete", {
              params: {
                id: id
              }
            })
            .then((res) => {
              if (!res.data.errorMsg) {
                this.$store.commit("sendAlert", {
                  msg: "Deleted",
                  color: "success"
                });
                this.deleteFromList(id);
                this.$store.dispatch("ccms/checkSchedule");
              } else {
                this.$store.commit("sendAlert", {
                  msg: res.data.errorMsg,
                  color: "error"
                });
              }
            });
        }
      });
    }
  },
  created() {
    this.loading = true;
    this.$axios
      .get("/telemarketer/schedule/user")
      .then((res) => {
        this.items = res.data;
      })
      .finally(() => {
        this.loading = false;
      });
  }
};
</script>
